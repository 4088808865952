input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 4px;
  height: 144px;
  outline: none;
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: #FE5219;
  cursor: pointer;
}

.volume-bar {
  writing-mode: vertical-lr;
  direction: rtl;
}