.menu {
  background-color: $white;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 40;
  width: 100%;
  align-items: center;
}

.logo {
  p {
    cursor: pointer;
    font-size: 40px;
    font-weight: 900;
  }
}

.nav-btn-hovered {
  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    display: inline-block;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(
      90deg,
      rgba(254, 82, 25, 0) 0%,
      rgba(254, 82, 25, 0.5) 50%,
      rgba(254, 82, 25, 0) 100%
    );
    animation: scale 0.5s 1;
  }
  &.is-logo {
    &::before,
    &::after {
      content: "";
      bottom: 0;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
